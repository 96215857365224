import React from "react"
import { default as Link } from "./link"

import PropTypes from "prop-types"
import TextBlock from "./text-block"

function Card({ title, image, link, classes, children }) {
    
    classes.unshift('card')
    
    if (link && !link.title)
        link.title = 'Mehr erfahren';
    
    let content = <>
        {image &&
            <div className="card__image">
                {image}
            </div>
        }
        <TextBlock title={title} level={3} classes={['card__content']}>
            {children}
        </TextBlock>
        {link &&
            <footer className="card__footer">
                <Link to={link.url} external={link.external} fake={link.full}>
                    {link.title}
                </Link>
            </footer>
        }
    </>
    
    if (link && link.full) {
        classes.push('card--link')
        
        return (
            <Link to={link.url} external={link.external} className={classes.join(' ')}>
                {content}
            </Link>
        )
    }
    
    return (
        <div className={classes.join(' ')}>
            {content}
        </div>
    )
}

Card.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.element,
    link: PropTypes.object
}

Card.defaultProps = {
    classes: []
}

export default Card
